import { useNavigate } from "@tanstack/react-location";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import React, { useEffect, useState } from "react";
import { db } from "../Services/firebase";

const ImageLister = (props: { annotator: string; project: string; }) => {
  const [allDocuments, setAllDocuments] = useState<firebase.firestore.DocumentData[]>([]);
  const [loading, setLoading] = useState(false);
  const [statistics, setStatistics] = useState<{
    numAnnotated: number;
    numSkipped: number;
  }>({ numAnnotated: 0, numSkipped: 0 });
  const navigate = useNavigate();

  useEffect(() => {
    const collection = db.collection(props.project);
    console.log("getAllDocuments");
    setLoading(true);
    let documents: firebase.firestore.DocumentData[] = [];
    let numAnnotated = 0;
    let numSkipped = 0;
    collection
      .orderBy("annotator", "desc")
      .limit(500)
      .get()
      .then((querySnapshot) => {
        console.log("querySnapshot", querySnapshot);
        querySnapshot.forEach((doc) => {
          // console.log('got', {[doc.id]: doc.data()})
          let data = doc.data();
          data.id = doc.id;
          documents.push(data);
          numAnnotated += data.annotator === null ? 0 : 1;
          numSkipped += data.isUncertain ? 1 : 0;
        });
        console.log("allDocs", documents);
        setAllDocuments(documents);
        setLoading(false);
        setStatistics({ numAnnotated, numSkipped });
      })
      .catch(function (error) {
        setLoading(false);

        console.error(error);
      });
  }, [props.project]);

  return (
    <div className="" role="main" tabIndex={0}>
      {
        <div>
          {loading && (
            <div>
              <h2>Loading data...</h2>
            </div>
          )}
          {!loading && (
            <div>
              <h3>
                {statistics["numAnnotated"]} out of {allDocuments.length}{" "}
                annotated, {statistics["numSkipped"]} skipped (limited to max
                100 document)
              </h3>
              <table style={{}} className="table">
                <thead>
                  <tr>
                    <th>Number</th>
                    <th>Document ID</th>
                    <th>Annotator</th>
                    <th>Actions</th>
                    <th>Annotations</th>
                    <th>Too Hard / Skipped</th>
                  </tr>
                </thead>
                <tbody>
                  {allDocuments.map((document, index) => (
                    <tr key={index}>
                      <td>{index}</td>
                      <td>{document.id}</td>
                      <td>
                        {document["annotator"] &&
                          document["annotator"].length &&
                          document["annotator"].map((name: string, index: number) => {
                            return <span key={index}>{name}, </span>;
                          })}
                      </td>
                      <td>
                        <button
                          type="button"
                          onClick={() =>
                            navigate({
                              to: `/image/${document.id}?annotator=${props.annotator}&project=${props.project}`,
                            })
                          }
                          className="btn btn-info"
                        >
                          Edit
                        </button>
                        {document["annotations"] &&
                          document["annotations"].length && (
                            <button
                              type="button"
                              onClick={() =>
                                navigate({
                                  to: `/image/${document.id}?annotator=${props.annotator}&project=${props.project}&review=true`,
                                })
                              }
                              className="btn btn-warning"
                            >
                              Review
                            </button>
                          )}
                      </td>
                      <td>
                        {document["annotations"] &&
                          document["annotations"].length}
                      </td>
                      <td>{document["isUncertain"] ? "yes" : ""}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      }
    </div>
  );
};
export default ImageLister;
