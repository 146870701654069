import { MakeGenerics, useSearch } from "@tanstack/react-location";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import "../App.css";
import ImageLister from "../Components/ImageLister";

type ListPageParams = MakeGenerics<{
  Search: {
    annotator?: string;
    project?: string;
  }
}>

function App() {
  const { annotator, project } = useSearch<ListPageParams>();

  return (
    <div>
      {annotator !== undefined && project !== undefined ? (
        <ImageLister annotator={annotator} project={project}></ImageLister>
      ) : (
        <div className={"dropzone"}>
          {annotator === undefined && <p>Annotator is missing</p>}
          {project === undefined && <p> Project is missing</p>}
        </div>
      )}
    </div>
  );
}

export default App;
