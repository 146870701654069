import React, { ReactNode, useMemo } from "react";
import { useState, useRef, useEffect } from "react";
import Konva from "konva";
import { useCallback } from "react";
import { Vector2d } from "konva/types/types";
import { Layer, Shape, Stage } from "react-konva";
import { useElementSize } from "usehooks-ts";
import { v4 as uuidv4 } from "uuid";

function base64DataToUrl(base64: string) {
  return "data:image/png;base64," + base64;
}

function urlToBase64Data(url: string) {
  return url.split(";base64,")[1];
}

function Button({
  children,
  className,
  onClick,
}: {
  children: ReactNode;
  className: string;
  onClick?: () => void;
}) {
  return (
    <button
      className={`backdrop-blur-md p-2 bg-[#000000aa] font-medium rounded-full enabled:text-white cursor-pointer hover:bg-black transition-all disabled:bg-[#bbbbbb] disabled:text-[#dddddd] disabled:backdrop-blur-none disabled:cursor-not-allowed ${className}`}
      onClick={onClick}
      disabled={onClick === undefined}
    >
      {children}
    </button>
  );
}
export interface Scribble {
  size: number;
  points: [number, number][];
  type: "negative" | "positive";
}

interface Brush extends Omit<Scribble, "points"> {
  position: Vector2d;
}

enum MaskingMode {
  MASK_ONLY = "MASK_ONLY",
  MASK = "MASK",
  MASK_INVERSE = "MASK_INVERSE",
  MASK_TRANSPARENT = "MASK_TRANSPARENT",
  MASK_FULLY_TRANSPARENT = "MASK_FULLY_TRANSPARENT",
}

async function loadImageFromURL(url: string) {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = "Anonymous"; // Necessary for getBase64Image to work.
    image.onload = () => resolve(image);
    image.onerror = reject;
    image.src = url;
  });
}

async function loadImageBlobFromURL(url: string) {
  return fetch(url).then((r) => r.blob());
}

async function loadImageFromBlob(blob: Blob) {
  return loadImageFromURL(URL.createObjectURL(blob));
}

function getBase64Image(img: HTMLImageElement) {
  const canvas = document.createElement("canvas");
  canvas.width = img.width;
  canvas.height = img.height;
  const context = canvas.getContext("2d");
  if (context !== null) {
    context.drawImage(img, 0, 0);
  }
  return canvas.toDataURL("image/jpeg", 1.0);
}

function colourForBrushType(type: "negative" | "positive") {
  switch (type) {
    case "positive":
      return "#00FFFF";
    case "negative":
      return "#FF0000";
    default:
      return "#00FF00";
  }
}

const drawScribbles = (
  ctx: Konva.Context,
  scribbles: Scribble[],
  width: number,
  height: number
) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore Eliot: no clue why this is occuring
  ctx.globalCompositeOperation = "source-over";

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore Eliot: no clue why this is occuring
  ctx.lineJoin = "round";
  scribbles.forEach((scribble) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore Eliot: no clue why this is occuring
    ctx.strokeStyle = colourForBrushType(scribble.type);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore Eliot: no clue why this is occuring
    ctx.lineWidth = scribble.size * width;
    scribble.points.forEach((point, index) => {
      if (index === 0) {
        return;
      }
      ctx.beginPath();

      const previousPoint = scribble.points[index - 1];

      ctx.moveTo(previousPoint[1] * width, previousPoint[0] * height);
      ctx.lineTo(point[1] * width, point[0] * height);
      ctx.closePath();
      ctx.stroke();
    });
  });
};

const InteractiveUI = ({
  loading,
  image,
  masks,
  scribbles,
  setScribbles,
  onClose,
  onUpload,
  onSkip,
}: {
  loading?: string;

  image?: HTMLImageElement;
  masks: {
    mask: HTMLImageElement | null | undefined;
    showScribbles: boolean;
  }[];

  scribbles?: Scribble[];
  setScribbles?: (scribbles: Scribble[]) => void;

  onUpload?: () => void;
  onSkip?: () => void;
  onClose: () => void;
}) => {
  const [parentRef, { width: parentWidth, height: parentHeight }] =
    useElementSize();
  const [maskingMode, setMaskingMode] = useState<MaskingMode>(MaskingMode.MASK);
  const [brushType, setBrushType] = useState<"positive" | "negative">(
    "negative"
  );
  const [authorizeUpload, setAuthorizeUpload] = useState(false);
  const stageRefs = useRef<(Konva.Stage | null)[]>([]);
  const dynamicLayerRefs = useRef<(Konva.Layer | null)[]>([]);
  const staticShapeRefs = useRef<(Konva.Shape | null)[]>([]);
  const brush = useRef<Brush | null>(null);
  const liveScribble = useRef<Scribble | null>(null);

  const stageWidth = parentWidth / 2.0;
  const stageHeight = parentHeight;

  const isScribbling = () => liveScribble.current !== null;

  const startScribbling = () => {
    if (
      brush.current !== null &&
      image !== undefined &&
      scribbles !== undefined
    ) {
      liveScribble.current = {
        ...brush.current,
        size: (brush.current.size / image.width) * 2,
        points: [],
      };
      doScribbling();
    }
  };

  const doScribbling = () => {
    if (
      liveScribble.current !== null &&
      brush.current !== null &&
      image !== undefined
    ) {
      liveScribble.current.points.push([
        brush.current.position.y / image.height,
        brush.current.position.x / image.width,
      ]);
    }
  };

  const stopScribbling = () => {
    if (liveScribble.current !== null) {
      if (setScribbles !== undefined && scribbles !== undefined) {
        setScribbles([...scribbles, liveScribble.current]);
      }
      liveScribble.current = null;
    }
  };

  const redrawStatic = useCallback(() => {
    for (const stageRef of stageRefs.current) {
      if (stageRef !== null) {
        stageRef.batchDraw();
      }
    }
  }, []);

  const redrawDynamic = useCallback(() => {
    for (const dynamicLayerRef of dynamicLayerRefs.current) {
      if (dynamicLayerRef !== null) {
        dynamicLayerRef.batchDraw();
      }
    }
  }, []);

  const setPosition = useCallback(
    (position: Vector2d) => {
      for (const stageRef of stageRefs.current) {
        if (stageRef !== null) {
          stageRef.position(position);
        }
      }
      redrawStatic();
    },
    [redrawStatic]
  );

  const setScale = useCallback(
    (scale: number) => {
      for (const stageRef of stageRefs.current) {
        if (stageRef !== null) {
          stageRef.scale({ x: scale, y: scale });
        }
      }
      redrawStatic();
    },
    [redrawStatic]
  );

  const getMinimumScale = useCallback(
    (image: HTMLImageElement) => {
      const scaleX = stageWidth / image.width;
      const scaleY = stageHeight / image.height;

      return Math.min(scaleX, scaleY);
    },
    [stageWidth, stageHeight]
  );

  const handleOnWheel = (event: Konva.KonvaEventObject<WheelEvent>) => {
    if (image !== undefined) {
      const scaleBy = 200.0;

      const stage = event.target.getStage();

      if (stage !== null) {
        const pointer = stage.getPointerPosition();

        if (pointer !== null) {
          const oldScale = stage.scaleX();

          const mousePointTo = {
            x: (pointer.x - stage.x()) / oldScale,
            y: (pointer.y - stage.y()) / oldScale,
          };

          const newScale = Math.max(
            oldScale - event.evt.deltaY / scaleBy,
            getMinimumScale(image)
          );

          setPosition({
            x: pointer.x - mousePointTo.x * newScale,
            y: pointer.y - mousePointTo.y * newScale,
          });
          setScale(newScale);
        }
      }
    }

    updateBrush(event.target.getStage());
  };

  const resetZoom = useCallback(() => {
    if (image !== undefined) {
      const newScale = getMinimumScale(image);

      setScale(newScale);

      setPosition({
        x: (stageWidth - image.width * newScale) / 2,
        y: (stageHeight - image.height * newScale) / 2,
      });
    }
  }, [image, getMinimumScale, stageWidth, stageHeight, setPosition, setScale]);

  const changeMaskingMode = useCallback(() => {
    if (loading === undefined) {
      setMaskingMode((maskingMode) => {
        switch (maskingMode) {
          case MaskingMode.MASK:
            return MaskingMode.MASK_INVERSE;
          case MaskingMode.MASK_INVERSE:
            return MaskingMode.MASK_ONLY;
          case MaskingMode.MASK_ONLY:
            return MaskingMode.MASK_TRANSPARENT;
          case MaskingMode.MASK_TRANSPARENT:
            setAuthorizeUpload(true);
            return MaskingMode.MASK_FULLY_TRANSPARENT;
          case MaskingMode.MASK_FULLY_TRANSPARENT:
            return MaskingMode.MASK;
        }
      });
    }
  }, [loading, setMaskingMode]);

  useEffect(() => {
    resetZoom();
  }, [resetZoom]);

  const sceneFuncStatic = (
    ctx: Konva.Context,
    showScribbles: boolean,
    mask: HTMLImageElement | null
  ) => {
    if (image !== undefined) {
      if (mask === null) {
        ctx.drawImage(image, 0, 0, image.width, image.height);
      } else {
        switch (maskingMode) {
          case MaskingMode.MASK_ONLY:
            ctx.drawImage(mask, 0, 0, image.width, image.height);
            break;
          case MaskingMode.MASK:
            ctx.drawImage(mask, 0, 0, image.width, image.height);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore Eliot: no clue why this is occuring
            ctx.globalCompositeOperation = "source-in";
            ctx.drawImage(image, 0, 0, image.width, image.height);
            break;
          case MaskingMode.MASK_TRANSPARENT:
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore Eliot: no clue why this is occuring
            ctx.globalAlpha = 0.3;
            ctx.drawImage(image, 0, 0, image.width, image.height);

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore Eliot: no clue why this is occuring
            ctx.globalAlpha = 0.99;
            ctx.drawImage(mask, 0, 0, image.width, image.height);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore Eliot: no clue why this is occuring
            ctx.globalCompositeOperation = "source-in";
            ctx.drawImage(image, 0, 0, image.width, image.height);
            // ctx.drawImage(mainImage, 0, 0, width, height)
            break;
          case MaskingMode.MASK_INVERSE:
            ctx.drawImage(mask, 0, 0, image.width, image.height);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore Eliot: no clue why this is occuring
            ctx.globalCompositeOperation = "source-out";
            ctx.drawImage(image, 0, 0, image.width, image.height);
            break;
          case MaskingMode.MASK_FULLY_TRANSPARENT:
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore Eliot: no clue why this is occuring
            ctx.globalAlpha = 0.05;
            ctx.drawImage(image, 0, 0, image.width, image.height);

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore Eliot: no clue why this is occuring
            ctx.globalAlpha = 1.0;
            ctx.drawImage(mask, 0, 0, image.width, image.height);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore Eliot: no clue why this is occuring
            ctx.globalCompositeOperation = "source-in";
            ctx.drawImage(image, 0, 0, image.width, image.height);
            break;

          default:
            break;
        }
      }

      // Draw scribbles

      if (showScribbles && scribbles !== undefined) {
        drawScribbles(ctx, scribbles, image.width, image.height);
      }
    }
  };

  const sceneFuncDynamic = (ctx: Konva.Context) => {
    // Draw current scribble

    if (liveScribble.current !== null && image !== undefined) {
      drawScribbles(ctx, [liveScribble.current], image.width, image.height);
    }

    // Draw brush

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore Eliot: no clue why this is occuring
    ctx.globalCompositeOperation = "source-over";

    if (scribbles !== undefined && brush.current !== null) {
      ctx.beginPath();
      ctx.arc(
        brush.current.position.x,
        brush.current.position.y,
        brush.current.size,
        0,
        2 * Math.PI,
        false
      );

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore Eliot: no clue why this is occuring
      ctx.fillStyle = colourForBrushType(brush.current.type);
      ctx.fill();
      ctx.closePath();
    }
  };

  const undo = useCallback(() => {
    if (
      scribbles !== undefined &&
      setScribbles !== undefined &&
      scribbles.length > 0
    ) {
      setScribbles(scribbles.slice(0, scribbles.length - 1));
    }
  }, [setScribbles, scribbles]);

  const updateBrush = (stage: Konva.Stage | null) => {
    if (stage !== null && loading === undefined) {
      const pointer = stage.getPointerPosition();

      if (pointer !== null) {
        const scale = stage.scaleX();

        const mousePointTo = {
          x: (pointer.x - stage.x()) / scale,
          y: (pointer.y - stage.y()) / scale,
        };

        brush.current = {
          position: {
            x: mousePointTo.x,
            y: mousePointTo.y,
          },
          size: 10 / scale,
          type: brushType,
        };

        redrawDynamic();
        return;
      }
    }

    brush.current = null;
    redrawDynamic();
  };

  const handleOnMouseMove = (event: Konva.KonvaEventObject<MouseEvent>) => {
    updateBrush(event.target.getStage());

    if (isScribbling()) {
      doScribbling();
      redrawDynamic();

      /* Necessary for the drag and drop animation to not work. */
      event.evt.stopImmediatePropagation();
    }
  };

  const handleOnMouseDown = (event: Konva.KonvaEventObject<MouseEvent>) => {
    if (event.evt.button === 0 && !event.evt.shiftKey) {
      startScribbling();
    }
  };

  const handleOnMouseUp = (event: Konva.KonvaEventObject<MouseEvent>) => {
    if (event.evt.button === 0) {
      stopScribbling();
    }
  };

  const changeBrushType = useCallback(() => {
    if (loading === undefined) {
      setBrushType((brushType) => {
        const nextBrushType =
          brushType === "negative" ? "positive" : "negative";
        if (brush.current !== null) {
          brush.current.type = nextBrushType;
        }
        return nextBrushType;
      });
    }
  }, [loading]);

  const upload = useCallback(() => {
    if (onUpload !== undefined) {
      onUpload();
    }
  }, [onUpload]);

  const skip = useCallback(() => {
    if (onSkip !== undefined) {
      onSkip();
    }
  }, [onSkip]);

  useEffect(() => {
    stageRefs.current.slice(0, 2);
    dynamicLayerRefs.current.slice(0, 2);
    staticShapeRefs.current.slice(0, 2);
  }, []);

  // TODO: Use proper hook library for handling keyboard.
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      switch (event.key) {
        case "d":
          changeMaskingMode();
          break;
        case " ":
          resetZoom();
          event.preventDefault();
          break;
        case "z":
          undo();
          break;
        case "t":
          changeBrushType();
          break;
        case "u":
          upload();
          break;
        case "k":
          skip();
          break;
        case "Escape":
          onClose();
          break;
        default:
          return;
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [
    changeMaskingMode,
    resetZoom,
    undo,
    upload,
    skip,
    onClose,
    changeBrushType,
  ]);

  // Trick to help useEffect work with arrays.
  const showScribbles = useMemo(() => {
    return JSON.stringify(masks.map((mask) => mask.showScribbles));
  }, [masks]);

  // Cache static shape and only update when image or scribbles changes.
  useEffect(() => {
    const parsedShowScribbles = JSON.parse(showScribbles);
    redrawStatic();
    staticShapeRefs.current.forEach((staticShapeRef, index) => {
      if (staticShapeRef !== null) {
        if (parsedShowScribbles[index]) {
          staticShapeRef.cache();
        }
      }
    });
  }, [scribbles, image, showScribbles, redrawStatic]);

  return (
    <div
      ref={parentRef}
      style={{ height: "100vh", display: "flex", flexDirection: "row" }}
      className="checkerboard-background"
    >
      {masks.map((mask, index) => (
        <div key={index}>
          <Stage
            width={stageWidth}
            height={stageHeight}
            draggable
            ref={(ref) => (stageRefs.current[index] = ref)}
            onDragMove={(event) => {
              setPosition(event.target.position());
            }}
            onWheel={handleOnWheel}
            onMouseMove={handleOnMouseMove}
            onMouseLeave={() => {
              updateBrush(null);
            }}
            onMouseDown={handleOnMouseDown}
            onMouseUp={handleOnMouseUp}
          >
            <Layer imageSmoothingEnabled={false} listening={false}>
              {image !== undefined && (
                <Shape
                  ref={(ref) => (staticShapeRefs.current[index] = ref)}
                  sceneFunc={(context) => {
                    if (mask.mask !== undefined) {
                      sceneFuncStatic(context, mask.showScribbles, mask.mask);
                    }
                  }}
                  width={image.width}
                  height={image.height}
                />
              )}
            </Layer>

            <Layer
              listening={false}
              ref={(ref) => (dynamicLayerRefs.current[index] = ref)}
            >
              {image !== undefined && (
                <Shape
                  sceneFunc={(context) => {
                    sceneFuncDynamic(context);
                  }}
                  width={image.width}
                  height={image.height}
                />
              )}
            </Layer>
          </Stage>
        </div>
      ))}

      <Button className="absolute top-4 left-4" onClick={() => onClose()}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </Button>

      {scribbles !== undefined && (
        <Button
          className="absolute top-28 right-4 py-2 px-4"
          onClick={loading !== undefined ? undefined : () => undo()}
        >
          Undo (z)
        </Button>
      )}

      {onUpload !== undefined && (
        <div className="absolute bottom-4 right-4 flex flex-row divide-x divide-black">
          <Button
            className="py-2 px-4 rounded-r-none"
            onClick={loading === undefined ? () => skip() : undefined}
          >
            Skip (k)
          </Button>

          <Button
            className="py-2 px-4 rounded-l-none"
            onClick={
              loading === undefined && authorizeUpload
                ? () => upload()
                : undefined
            }
          >
            Upload (u)
          </Button>
        </div>
      )}

      <Button
        className="absolute top-4 right-4 py-2 px-4"
        onClick={loading !== undefined ? undefined : () => changeMaskingMode()}
      >
        Masking mode (d)
      </Button>

      {scribbles !== undefined && (
        <Button
          className="absolute top-16 right-4 py-2 px-4"
          onClick={loading !== undefined ? undefined : () => changeBrushType()}
        >
          Brush type (t)
        </Button>
      )}

      {loading !== undefined && (
        <Button
          className="absolute left-1/2 -translate-x-1/2 bottom-4 flex flex-row items-center"
          onClick={() => { }}
        >
          <div className="lds-dual-ring"></div>
          <span className="px-4">{loading}</span>
        </Button>
      )}
    </div>
  );
};

export const InteractiveUI2Review = ({
  imageSrc,
  maskSrc,
  onClose,
}: {
  imageSrc: string;
  maskSrc: string;
  onClose: () => void;
}) => {
  const [image, setImage] = useState<HTMLImageElement | undefined>(undefined);
  const [mask, setMask] = useState<HTMLImageElement | undefined>(undefined);

  useEffect(() => {
    setImage(undefined);
    loadImageFromURL(imageSrc).then((image) => {
      setImage(image);
    });
  }, [imageSrc]);

  useEffect(() => {
    setMask(undefined);
    if (maskSrc !== null) {
      loadImageFromURL(maskSrc).then((mask) => {
        setMask(convertGrayscaleToRGBA(mask));
      });
    }
  }, [maskSrc]);

  const loading = useMemo(() => {
    if (image === undefined && mask === undefined) {
      return "Loading image and mask";
    } else if (image === undefined && mask !== undefined) {
      return "Loading image";
    } else if (image !== undefined && mask === undefined) {
      return "Loading mask";
    } else {
      return undefined;
    }
  }, [image, mask]);

  return (
    <InteractiveUI
      loading={loading}
      image={image}
      masks={[
        { mask: null, showScribbles: true },
        { mask: mask, showScribbles: false },
      ]}
      onClose={onClose}
    />
  );
};

// const serverUrl = "https://api-supahands.artizans.ai/";
const serverUrl = "https://dev-api-supahands.photoroom.dev/";


async function getInitialMask(imageBlob: Blob) {
  const formData = new FormData();
  console.log(imageBlob.size);
  formData.append("image_file", imageBlob);
  formData.append("channels", "alpha");

  const request: RequestInit = {
    method: "POST",
    headers: {
      "X-Api-Key": "37fe3503d854c56d429a7a720d6435c605cad36a",
      secret_bypass: "true",
    },
    body: formData,
  };

  return fetch(`${serverUrl}v1/segment`, request).then((res) => res.blob());
}

async function sendMask(
  uuid: string,
  scribbles: Scribble[],
  image?: HTMLImageElement,
  mask?: HTMLImageElement
) {
  const body = {
    uuid,
    strokes: scribbles,
    ...(image === undefined
      ? {}
      : { b64_img: urlToBase64Data(getBase64Image(image)) }),
    ...(mask === undefined
      ? {}
      : { b64_mask: urlToBase64Data(getBase64Image(mask)) }),
  };

  const request: RequestInit = {
    method: "POST",
    headers: {
      accept: "application/json",
      "content-type": "application/json",
      "X-Api-Key": "37fe3503d854c56d429a7a720d6435c605cad36a",
      secret_bypass: "true",
    },

    body: JSON.stringify(body),
  };

  return await fetch(`${serverUrl}v3/interactive_segmentation`, request)
    .then((response) => response.json())
    .then((data) => {
      return data["b64_mask"] as string;
    });
}

export const InteractiveUI2Edit = ({
  imageSrc,
  initialScribbles,
  upload,
  skip,
  onClose,
}: {
  imageSrc: string;
  initialScribbles: Scribble[];
  upload: (mask: string, scribbles: Scribble[]) => Promise<void>;
  skip: (mask: string, scribbles: Scribble[]) => Promise<void>;
  onClose: () => void;
}) => {
  const [image, setImage] = useState<HTMLImageElement | undefined>(undefined);

  const [mask, setMask] = useState<HTMLImageElement | undefined>(undefined);
  const [maskBase64, setMaskBase64] = useState<string | undefined>(undefined);
  const [uuid, setUuid] = useState<string | undefined>(undefined);
  const [scribbles, setScribbles] = useState<Scribble[]>(initialScribbles);
  const [loading, setLoading] = useState<string | undefined>(
    "Downloading image"
  );

  useEffect(() => {
    setLoading("Downloading image");

    loadImageBlobFromURL(imageSrc).then((imageBlob) => {
      loadImageFromBlob(imageBlob).then(
        (image) => {
          const uuid = uuidv4();

          setImage(image);
          setUuid(uuid);
          setLoading("Downloading initial mask");
          console.log(imageBlob.size);
          getInitialMask(imageBlob).then((maskPNG) => {
            loadImageFromBlob(maskPNG).then((mask) => {
              setLoading("Updating initial mask");
              sendMask(uuid, initialScribbles, image, mask).then(
                (maskBase64) => {
                  loadImageFromURL(base64DataToUrl(maskBase64)).then((mask) => {
                    setMask(convertGrayscaleToRGBA(mask));
                    setMaskBase64(maskBase64);
                    setLoading(undefined);
                  });
                }
              );
            });
          });
        }
      );
    });
  }, [imageSrc, initialScribbles]);

  const onUpload = () => {
    if (maskBase64 !== undefined) {
      setLoading("Uploading mask");
      upload(base64DataToUrl(maskBase64), scribbles).then(() => {
        setLoading(undefined);
      });
    }
  };

  const onSkip = () => {
    if (maskBase64 !== undefined) {
      setLoading("Uploading mask (skip)");
      skip(base64DataToUrl(maskBase64), scribbles).then(() => {
        setLoading(undefined);
      });
    }
  };

  const updateScribbles = (scribbles: Scribble[]) => {
    if (uuid !== undefined) {
      sendMask(uuid, scribbles).then((maskBase64) => {
        loadImageFromURL(base64DataToUrl(maskBase64)).then((mask) => {
          setMask(convertGrayscaleToRGBA(mask));
          setMaskBase64(maskBase64);
          setScribbles(scribbles);
        });
      });
    }
  };

  return (
    <InteractiveUI
      loading={loading}
      image={image}
      scribbles={scribbles}
      setScribbles={updateScribbles}
      masks={[
        { mask: null, showScribbles: true },
        { mask: mask, showScribbles: false },
      ]}
      onUpload={onUpload}
      onSkip={onSkip}
      onClose={onClose}
    />
  );
};

const convertGrayscaleToRGBA = function (maskImage: HTMLImageElement) {
  const canvas = document.createElement("canvas");
  let ctx = canvas.getContext("2d");

  if (ctx === null) {
    return maskImage;
  }
  canvas.width = maskImage.width;
  canvas.height = maskImage.height;
  ctx.drawImage(maskImage, 0, 0, maskImage.width, maskImage.height);
  let maskImageData = ctx.getImageData(
    0,
    0,
    maskImage.width,
    maskImage.height
  );

  let px = maskImageData.data;
  for (let i = 0; i < px.length; i += 4) {
    px[i + 3] = px[i];
    px[i] = 255;
    px[i + 1] = 255;
    px[i + 2] = 255;
  }
  ctx.putImageData(
    maskImageData,
    0,
    0,
    0,
    0,
    maskImage.width,
    maskImage.height
  );

  const image = new Image();
  image.src = canvas.toDataURL();
  return image;

};
