import firebase from "firebase/app";
import 'firebase/firestore';
import 'firebase/storage';

const firebaseConfig = {

  apiKey: "AIzaSyAwFSVw36a3c_3nytmpEgCMuoTtNnRQfYg",
  authDomain: "supahands-annotation.firebaseapp.com",
  projectId: "supahands-annotation",
  storageBucket: "supahands-annotation.appspot.com",
  messagingSenderId: "241892053110",
  appId: "1:241892053110:web:96cead17572755ac8f4f57",
  measurementId: "G-TXX27ERD28"
};

if (firebase.apps.length === 0 ) {
  firebase.initializeApp(firebaseConfig);
}


export var db = firebase.firestore();
export var storage = firebase.storage();
export var storageRef = storage.ref();