import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import AppRouter from "./Components/router";


ReactDOM.render(
  <React.StrictMode>
     <AppRouter></AppRouter>

  </React.StrictMode>,
  document.getElementById('root')
);
