import {
  Outlet,
  ReactLocation,
  Router,
} from "@tanstack/react-location";
import React from "react";
import EditPage from '../Pages/EditPage'
import ListPage from '../Pages/ListPage'

const location = new ReactLocation();


export default function AppRouter() {
  return (
    <Router
      location={location}
      routes={[
        {
          path: "/",
          element: <ListPage></ListPage>,
        },
        { path: "/image/:imageId", element: <EditPage/> },

      ]}
    >
      <Outlet />
    </Router>
  );
}
